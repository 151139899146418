import { ref, type Ref, watch } from 'vue'
import type { FormData, SiteFormsKey } from '../types'
import { lsKey, sleep } from './utils'
import { siteForm } from '@/router/siteStructure'

export class SiteForm<T extends SiteFormsKey> {
  data: Ref<FormData<T>>
  readonly initData: string
  clearable = ref(false)
  status: Ref<'new' | 'sent' | 'error' | 'departs'> = ref('new')
  serverMessage?: string
  formKey: SiteFormsKey
  item: (typeof siteForm)[T]

  constructor(formKey: SiteFormsKey, ls?: true) {
    this.formKey = formKey
    this.item = siteForm[formKey]
    this.initData = JSON.stringify(
      Object.fromEntries(Object.entries(this.item.form).map(([k, v]) => [k, v.initial]))
    )
    const strData = ls ? (localStorage.getItem(lsKey(formKey)) ?? this.initData) : this.initData
    this.data = ref(JSON.parse(strData))
    this.clearable.value = this.isClearable(strData)

    if (ls) {
      watch(
        this.data,
        (newVal) => {
          const str = JSON.stringify(newVal)
          localStorage.setItem(lsKey(formKey), str)
          this.clearable.value = this.isClearable(str)
        },
        {
          deep: true
        }
      )
    }
  }

  private isClearable(data?: string) {
    data ??= JSON.stringify(this.data.value)
    return data !== this.initData
  }

  clear() {
    this.data.value = JSON.parse(this.initData)
  }

  async send() {
    this.status.value = 'departs'
    await sleep(500) //TODO?

    this.status.value = 'error'

    // try {
    //   this.serverMessage = await siteApi.request(siteForm[this.formKey].apiKey, this.dataPrepare)
    //   this.status.value = 'sent'
    // } catch (error) {
    //   this.serverMessage = (error as Error).message
    //   this.status.value = 'error'
    // }
  }

  private get dataPrepare(): FormData<T> & { head1: string } {
    return {
      ...(Object.fromEntries(
        Object.entries(this.data.value).map(([k, v]) => [k, this.dataTransformByKey(k, v)])
      ) as FormData<T>),
      ...{ head1: this.item.head1 }
    }
  }

  private dataTransformByKey(key: string, data: string | undefined) {
    if ((this.item.form as any)[key]?.bind.options)
      return (this.item.form as any)[key].bind.options[data!]
    else return data
  }
}
