<script setup lang="ts">
import HorizontalSliderComponent from '@/components/widgets/SliderComponent.vue'
import AboutUsItemComponent from '@/components/widgets/AboutUsItemComponent.vue'
import type { AboutUsItem } from '@/types'

const items: AboutUsItem[] = [
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек»',
    date: '7 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  },
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек»',
    date: '8 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  },
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек»',
    date: '9 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  },
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек»',
    date: '10 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  },
  {
    image: '1.webp',
    topic: '«Россияне на самоизоляции накупили секс-игрушек»',
    date: '11 июня 2021',
    source: 'Lenta.ru',
    url: 'https://lenta.ru'
  }
]
</script>
<template>
  <div class="AboutUs">
    <div class="horizontal-limiter">
      <HorizontalSliderComponent class="_list" name="О нас говорят" orientation="vertical">
        <AboutUsItemComponent :key="k" v-for="[k, item] of items.entries()" v-bind="item" />
      </HorizontalSliderComponent>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.AboutUs {
  padding-top: 60px;
  padding-bottom: 60px;
  ._list {
    height: 300px;
  }
}
</style>
