import type { StyleValue } from 'vue'
import type { IconDescription, IconProps } from '../types'
import { ICONS } from './const'

/**
 * Класс для управления иконками
 */
export class IconProcessor {
  iconStructure: typeof ICONS
  mapDefs: string = ''
  orientationValues = {
    down: 270,
    top: 90,
    left: 180,
    right: 0
  }

  /**
   * Конструктор класса IconProcessor
   *
   * @param iconStr
   */
  constructor(iconStr: typeof ICONS) {
    this.iconStructure = iconStr
    for (const [id, desc] of Object.entries(iconStr)) {
      if (desc.symbol === false) continue
      this.mapDefs += `<symbol id="${id}" viewBox="${desc.viewBox.x} ${desc.viewBox.y} ${desc.viewBox.w} ${desc.viewBox.h}" fill="currentColor">${desc.symbol}</symbol>`
    }
  }

  svg(props: IconProps): {
    style?: StyleValue
    id: string
  } {
    const iconD = this.iconStructure[props.iconId]

    const color = props.iconColor ?? iconD.defaultColor

    let width = props.iconWidth
    let height = props.iconHeight

    if (!props.noCalculate) {
      if (width === undefined && height === undefined) {
        width = iconD.viewBox.w * (iconD.zoom ?? 1)
        height = iconD.viewBox.h * (iconD.zoom ?? 1)
      } else if (width !== undefined && height !== undefined) {
        /** уже заданы */
      } else if (width !== undefined) {
        height = (iconD.viewBox.h * width) / iconD.viewBox.w
      } else if (height !== undefined) {
        width = (iconD.viewBox.w * height) / iconD.viewBox.h
      }
    }

    const rotate = iconProcessor.computeTransformRotate(props.iconRotate, iconD.defaultOrientation)
    const style: StyleValue = {
      width: width ? `${width}px` : undefined,
      height: height ? `${height}px` : undefined,
      color,
      transform: rotate ? `rotate(${rotate}deg)` : undefined
    }

    return {
      style,
      id: `#${props.iconId}`
    }
  }

  /**
   * Поворот иконок
   *
   * @param orientation
   * @param defaultOrientation
   * @returns
   */
  computeTransformRotate(
    orientation?: IconDescription['defaultOrientation'] | number,
    defaultOrientation?: IconDescription['defaultOrientation']
  ): number | undefined {
    defaultOrientation ??= 'top'
    if (!orientation) return undefined
    if (typeof orientation === 'number') return orientation
    return this.orientationValues[defaultOrientation] - this.orientationValues[orientation]
  }
}

export const iconProcessor = new IconProcessor(ICONS)
