import type {
  MenuStructure,
  SitePointKeys,
  SiteStructureFolder,
  SiteStructurePoint,
  SecondaryTopMenu,
  FeedbackForm,
  FormsStructureItem
} from '@/types'
import HomePage from '../views/HomeView.vue'
import NotFoundPage from '../views/NotFoundView.vue'
import { NOT_FOUND_PATH } from '@/lib/const'
import InputComponent from '@/components/elements/InputComponent.vue'
import SelectComponent from '@/components/elements/SelectComponent.vue'

/** структура форм */
export const siteForm = {
  form1v1: <FormsStructureItem<FeedbackForm>>{
    apiKey: 'feedbackForm',
    form: {
      type: {
        component: SelectComponent,
        bind: {
          placeholder: 'Выберите',
          label: 'Тип сотрудничества',
          required: true,
          type: 'select',
          options: {
            type1: 'тип 1',
            type2: 'тип 2',
            type3: 'тип 3',
            type4: 'тип 4',
            type5: 'тип 5'
          }
        }
      },
      name: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Имя'
        }
      },
      phone: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Телефон',
          type: 'phone'
        }
      },
      email: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Электронная почта',
          type: 'email'
        }
      },
      message: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Комментарий',
          type: 'textarea'
        }
      }
    },
    dataConsent: true,
    head1: 'Начать сотрудничество',
    sendButtonText: 'Отправить заявку'
  },
  form2v1: <FormsStructureItem<FeedbackForm>>{
    apiKey: 'feedbackForm',
    form: {
      type: {
        component: SelectComponent,
        bind: {
          type: 'select',
          placeholder: 'Выберите',
          label: 'Тариф',
          options: {
            type1: 'тариф 1',
            type2: 'тариф 2',
            type3: 'тариф 3',
            type4: 'тариф 4'
          }
        }
      },
      name: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Имя'
        }
      },
      phone: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Телефон',
          type: 'phone'
        }
      },
      email: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Электронная почта',
          type: 'email'
        }
      },
      message: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          label: 'Комментарий',
          type: 'textarea'
        }
      }
    },
    dataConsent: true,
    head1: 'Оставить заявку на сотрудничество',
    sendButtonText: 'Отправить заявку'
  },
  form3v1: <FormsStructureItem<FeedbackForm>>{
    apiKey: 'feedbackForm',
    form: {
      name: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Контактное лицо (ФИО)'
        }
      },
      phone: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Телефон',
          type: 'phone'
        }
      },
      email: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Электронная почта',
          type: 'email'
        }
      },
      message: {
        component: InputComponent,
        bind: {
          placeholder: 'Укажите интересующее направление работы',
          label: 'Комментарий',
          type: 'textarea'
        }
      }
    },
    dataConsent: true,
    head1: 'Зарегистрироваться на платформе',
    sendButtonText: 'Отправить заявку'
  },
  form4v1: <FormsStructureItem<FeedbackForm>>{
    apiKey: 'feedbackForm',
    form: {
      type: {
        component: SelectComponent,
        bind: {
          type: 'select',
          placeholder: 'Выберите',
          label: 'Тариф',
          options: {
            type1: 'тариф 1',
            type2: 'тариф 2',
            type3: 'тариф 3',
            type4: 'тариф 4'
          }
        }
      },
      name: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Имя'
        }
      },
      phone: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Телефон',
          type: 'phone'
        }
      },
      email: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Электронная почта',
          type: 'email'
        }
      },
      message: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          label: 'Комментарий',
          type: 'textarea'
        }
      }
    },
    dataConsent: true,
    head1: 'Гладиатор от Поставщика счастья – это помощь на всех этапах работы с маркетплейсами',
    head2:
      'Станьте «профессиональным бойцом» на маркетплейсах в кратчайшие сроки<br />Гладиатор сэкономит ваши ресурсы и высвободит время и силы для других задач',
    sendButtonText: 'Отправить заявку'
  },
  form5v1: <FormsStructureItem<FeedbackForm>>{
    apiKey: 'feedbackForm',
    form: {
      type: {
        component: SelectComponent,
        bind: {
          type: 'select',
          placeholder: 'Выберите',
          label: 'Тариф',
          options: {
            type1: 'тариф 1',
            type2: 'тариф 2',
            type3: 'тариф 3',
            type4: 'тариф 4'
          }
        }
      },
      name: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Имя'
        }
      },
      phone: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Телефон',
          type: 'phone'
        }
      },
      email: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          required: true,
          label: 'Электронная почта',
          type: 'email'
        }
      },
      message: {
        component: InputComponent,
        bind: {
          placeholder: 'Введите',
          label: 'Комментарий',
          type: 'textarea'
        }
      }
    },
    dataConsent: true,
    head1: 'Работать с нами',
    sendButtonText: 'Отправить заявку'
  }
}

/** структура меню/футера */
export const menuStructure: MenuStructure = {
  главная: {
    селлерам: {
      'бизнес на маркетплейсах': true,
      'как это работает': true,
      'с чего начать': true,
      'быстрый старт на маркетплейсах': true,
      'выбор ассортимента': true,
      'FBS, FBO, DBS': true,
      'автоматическое управление заказами': true
    },
    'интернет-магазинам': {
      'форматы работы': true,
      'как это происходит': true,
      'алгоритм действий': true,
      дропшиппинг: true,
      упаковка: true,
      доставка: true,
      'готовые интернет-магазины': true
    },
    'оптовым покупателям': {
      'наши преимущества': true,
      'схемы взаимодействия': true,
      'условия работы для оптовых покупателей': true,
      'способы доставки': true,
      'склады в Москве': true,
      'совместные покупки': true
    },
    'условия работы': {
      'системы скидок': true,
      'политика РРЦ': true,
      'поддержка и гарантии': true,
      'API и выгрузки': true,
      инструкции: true,
      'базы знаний': true
    },
    фулфилмент: {
      хранение: true,
      'упаковка в фулфилмент': true,
      отгрузка: true,
      'доставка в фулфилмент': true,
      учёт: true,
      'защита и гарантии': true
    },
    'продавцам в соцсетях и сообществах': {
      'способы взаимодействия': true,
      'продавцам в соцсетях': true,
      'продавцам в Телеграм': true,
      'продавцам в сообществах': true,
      'программа лояльности': true,
      'поддержка и гарантии в соцсети': true,
      'инструкции для продавцов в сообществах': true
    },
    'производителям и дистрибуторам': {
      'схемы работы': true,
      'требования к настройкам': true,
      'требования к отгрузкам': true,
      'требования к документации': true,
      'работа с РРЦ': true,
      'фулфилмент на ТТК': true
    },
    'о компании': {
      новости: true,
      'история и миссия': true,
      команда: true,
      бренды: true,
      'отзывы и кейсы': true,
      'СМИ о нас': true,
      сотрудничество: true,
      контакты: true,
      'ответы на частые вопросы': true
    },
    спецпредложения: {
      'банк Точка': true,
      робокасса: true,
      AdvantShop: true,
      InSales: true,
      'огонь — готовый сайт': true,
      мероприятия: true,
      'все предложения': true,
      'сервисным партнёрам': true
    }
  }
}

/** структура вторичного меню */
export const secondaryTopMenu: SecondaryTopMenu = {
  first: {
    'FBS, FBO, DBS': '18+',
    'API и выгрузки': 'Бельё',
    'схемы работы': 'Красота и уход',
    'требования к документации': 'Сад, дача и отдых',
    'фулфилмент на ТТК': true,
    'автоматическое управление заказами': true
  },
  second: {
    'бизнес на маркетплейсах': 'Выйти на маркетплейсы',
    'алгоритм действий': 'Открыть интернет-магазин',
    'схемы работы': 'Стать поставщиком'
  }
}

/** поинт для главной */
export const siteStructureMainPoint: SiteStructurePoint = {
  hide: {
    h1: true,
    breadcrumb: true,
    leftMenu: true,
    limiter: true
  },
  path: '/',
  title:
    'Поставщик счастья - крупнейший поставщик товаров для маркетплейсов оптом: товары для взрослых, нижнее белье, косметика...',
  component: HomePage,
  head: {
    meta: [
      {
        name: 'description',
        content:
          'Автоматизация процессов для предпринимателей на маркетплейсах и в e-commerce. Полный цикл подготовки и отправки заказов. Настоящий дропшиппинг без закупки и хранения товара.'
      },
      {
        name: 'keywords',
        content:
          'поставщик счастья, поставщик товаров для взрослых, товары 18+, товары для маркетплейсов'
      }
    ]
  }
}

/** все элементы маршрутизации: папки и поинты */
export const siteItems = {
  //
  //
  // папки
  селлерам: <SiteStructureFolder>true,
  'интернет-магазинам': <SiteStructureFolder>true,
  'оптовым покупателям': <SiteStructureFolder>true,
  'условия работы': <SiteStructureFolder>true,
  фулфилмент: <SiteStructureFolder>true,
  'продавцам в соцсетях и сообществах': <SiteStructureFolder>true,
  'производителям и дистрибуторам': <SiteStructureFolder>true,
  'о компании': <SiteStructureFolder>true,
  спецпредложения: <SiteStructureFolder>true,

  //
  //
  // поинты
  главная: siteStructureMainPoint,

  //
  // селлерам
  'бизнес на маркетплейсах': <SiteStructurePoint>{
    component: () => import('../views/forSellers/MarketplacesView.vue')
  },
  'как это работает': <SiteStructurePoint>{
    component: () => import('../views/forSellers/HowItWorksView.vue')
  },
  'с чего начать': <SiteStructurePoint>{
    component: () => import('../views/forSellers/WhereToBeginView.vue')
  },
  'быстрый старт на маркетплейсах': <SiteStructurePoint>{
    component: () => import('../views/forSellers/FastStartView.vue')
  },
  'выбор ассортимента': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'FBS, FBO, DBS': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'автоматическое управление заказами': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },

  //
  // интернет-магазинам
  'форматы работы': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'как это происходит': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'алгоритм действий': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  дропшиппинг: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  упаковка: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  доставка: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'готовые интернет-магазины': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },

  //
  // оптовым покупателям
  'наши преимущества': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'схемы взаимодействия': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'условия работы для оптовых покупателей': <SiteStructurePoint>{
    alias: 'условия работы',
    component: () => import('../views/DummyView.vue')
  },
  'способы доставки': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'склады в Москве': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'совместные покупки': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },

  //
  // условия работы
  'системы скидок': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'политика РРЦ': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'поддержка и гарантии': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'API и выгрузки': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  инструкции: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'базы знаний': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },

  //
  // фулфилмент
  хранение: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'упаковка в фулфилмент': <SiteStructurePoint>{
    alias: 'упаковка',
    component: () => import('../views/DummyView.vue')
  },
  отгрузка: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'доставка в фулфилмент': <SiteStructurePoint>{
    alias: 'доставка',
    component: () => import('../views/DummyView.vue')
  },
  учёт: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'защита и гарантии': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },

  //
  // продавцам в соцсетях и сообществах
  'способы взаимодействия': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'продавцам в соцсетях': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'продавцам в Телеграм': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'продавцам в сообществах': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'программа лояльности': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'поддержка и гарантии в соцсети': <SiteStructurePoint>{
    alias: 'поддержка и гарантии',
    component: () => import('../views/DummyView.vue')
  },
  'инструкции для продавцов в сообществах': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },

  //
  // производителям и дистрибуторам
  'схемы работы': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'требования к настройкам': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'требования к отгрузкам': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'требования к документации': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'работа с РРЦ': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'фулфилмент на ТТК': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },

  //
  // о компании
  новости: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'история и миссия': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  команда: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  бренды: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'отзывы и кейсы': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'СМИ о нас': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  сотрудничество: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  контакты: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'ответы на частые вопросы': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },

  //
  // спецпредложения
  'банк Точка': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  робокасса: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  AdvantShop: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  InSales: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'огонь — готовый сайт': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  мероприятия: <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'все предложения': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },
  'сервисным партнёрам': <SiteStructurePoint>{
    component: () => import('../views/DummyView.vue')
  },

  //
  // ----- одиночные
  'политика конфиденциальности': <SiteStructurePoint>{
    hide: {
      leftMenu: true
    },
    component: () => import('../views/DummyView.vue')
  },
  'согласие на обработку персональных данных': <SiteStructurePoint>{
    hide: {
      leftMenu: true
    },
    component: () => import('../views/DummyView.vue')
  },
  'поиск по сайту': <SiteStructurePoint>{
    hide: {
      leftMenu: true
    },
    component: () => import('../views/SearchView.vue')
  },
  'вход на сайт': <SiteStructurePoint>{
    hide: {
      leftMenu: true
    },
    component: () => import('../views/UnderConstructionView.vue')
  },

  //
  // ----- в разработке
  каталог: <SiteStructurePoint>{
    hide: {
      leftMenu: true
    },
    component: () => import('../views/UnderConstructionView.vue')
  },

  // Обработка отсутствующих маршрутов (страница 404)
  'страница не найдена': <SiteStructurePoint>{
    alias: '404 — страница не найдена',
    path: NOT_FOUND_PATH,
    hide: {
      leftMenu: true
    },
    component: NotFoundPage
  }
} as const

export const siteStructureMainPointKey: SitePointKeys = Object.entries(siteItems).find(
  ([, v]) => v === siteStructureMainPoint
)![0] as SitePointKeys

export const siteStructureNotFoundPointKey: SitePointKeys = Object.entries(siteItems).find(
  ([, v]) => (v as SiteStructurePoint).path === NOT_FOUND_PATH
)![0] as SitePointKeys
