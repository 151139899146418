<script lang="ts" setup>
import { LINK_TG, TEL_MAIN } from '@/lib/const'
import { topMenu } from '@/lib/topMenu'
import { strCap } from '@/lib/utils'
import { siteRouter } from '@/router'
import { onMounted, onUnmounted } from 'vue'
import SiteItem from '../elements/SiteItemComponent.vue'
import type { MenuStructure, SiteFolderKeys, SiteItemKeys } from '@/types'
import IconComponent from '../icons/IconComponent.vue'

const activeMenuItemEntries = Object.entries(siteRouter.activeMenuItemEntries)

const onClick = (event: Event) => {
  if (topMenu.openMainMenu.value) {
    const openMenu = document.querySelector(`div[data-id="${topMenu.openMainMenu.value}"]`)
    if (openMenu !== event.target && !openMenu?.contains(event.target as Node))
      topMenu.openMainMenu.value = null
  }
}

onMounted(() => {
  window.addEventListener('click', onClick)
})

onUnmounted(() => {
  window.removeEventListener('click', onClick)
})
</script>
<template>
  <div class="TopMenu" id="TopMenu">
    <div class="horizontal-limiter">
      <div class="_content">
        <div class="_menu opacity">
          <div
            :data-id="groupName"
            class="_group"
            :class="{ _open: topMenu.openMainMenu.value === groupName }"
            :key="groupName"
            v-for="[groupName, groupItems] of activeMenuItemEntries.slice(
              0,
              topMenu.horizontalMainViewLenght.value
            ) as [SiteFolderKeys, MenuStructure][]"
          >
            <div class="_folder" v-on:click="topMenu.menuClick(groupName)">
              <span v-html="strCap(groupName)" />
              <IconComponent
                icon-id="svg-chevron"
                :icon-rotate="topMenu.openMainMenu.value === groupName ? 'top' : 'down'"
              />
            </div>
            <div class="_items">
              <div
                :key="item"
                v-for="item of Object.keys(groupItems) as SiteItemKeys[]"
                v-on:click="topMenu.menuClick(groupName, 1000)"
              >
                <SiteItem class="_item-link" :site-key="item" />
              </div>
            </div>
          </div>

          <div
            id="_menu-more"
            data-id="+"
            class="_group"
            :class="{ _open: topMenu.openMainMenu.value === '+' }"
          >
            <div class="_folder" v-on:click="topMenu.menuClick('+')">
              <span>Ещё</span>
              <IconComponent
                icon-id="svg-chevron"
                :icon-rotate="topMenu.openMainMenu.value === '+' ? 'top' : 'down'"
              />
            </div>
            <div class="_items">
              <div
                class="_group-more"
                :class="{ _open: topMenu.openMainMenuMore.value === groupName }"
                :key="groupName"
                v-for="[groupName, groupItems] of activeMenuItemEntries.slice(
                  -(activeMenuItemEntries.length - topMenu.horizontalMainViewLenght.value)
                ) as [SiteFolderKeys, MenuStructure][]"
              >
                <div class="_folder-more" v-on:click="topMenu.menuMoreClick(groupName)">
                  <IconComponent
                    icon-id="svg-chevron"
                    :icon-rotate="topMenu.openMainMenuMore.value === groupName ? 'top' : 'down'"
                  />
                  <span v-html="strCap(groupName)" />
                </div>
                <div class="_items-more">
                  <div
                    :key="item"
                    v-for="item of Object.keys(groupItems) as SiteItemKeys[]"
                    v-on:click="topMenu.menuClick(groupName, 1000)"
                  >
                    <SiteItem class="_item-link" :site-key="item" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="_right-block">
          <a :href="LINK_TG" class="_tg">
            <IconComponent icon-id="svg-telegram" />
          </a>
          <a class="_phone" :href="`tel://${TEL_MAIN}`">{{ TEL_MAIN }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.TopMenu {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: var(--bg-dark);
  color: white;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  word-wrap: break-word;
  height: 30px;
  max-height: 30px;
  ._content {
    position: relative;
    ._menu {
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      display: flex;
      ._folder {
        display: inline-flex;
        align-items: center;
        margin-right: 20px;
        height: 30px;
        cursor: pointer;
      }
      ._folder-more {
        display: inline-flex;
        align-items: center;
        margin-right: 20px;
        height: 20px;
        cursor: pointer;
      }
      ._items {
        position: absolute;
        padding: 20px;
        background-color: var(--bg-dark);
        flex-direction: column;
        gap: 20px;
        ._folder {
          height: auto;
          span {
            white-space: normal;
          }
        }
      }
      ._group {
        display: inline-block;
        ._items {
          display: none;
        }
        &._open {
          ._items {
            display: flex;
          }
        }
      }
      ._group-more {
        display: inline-block;
        ._items-more {
          display: none;
        }
        &._open {
          ._items-more {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px 0 0 30px;
          }
        }
      }
      #_menu-more {
        display: inline-block;
        ._items {
          margin-left: clamp(-100px, -10%, 0px);
        }
      }
    }
    ._right-block {
      display: flex;
      align-items: center;
      height: 30px;
      position: absolute;
      background-color: var(--bg-dark);
      right: 0;
      top: 0;
      a {
        color: #ffffff;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}
._tg {
  margin-right: 15px;
  position: relative;
  width: 20px;
  top: 1px;
}
._item-link {
  color: #ffffff;
  &:hover {
    font-weight: 700;
  }
  &.router-link-active {
    text-decoration: underline;
  }
}

@media (max-width: 800px) {
  ._phone {
    display: none;
  }
  ._tg {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .TopMenu {
    top: -30px;
  }
}
</style>
