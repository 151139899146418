import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouteLocationNormalizedLoaded,
  type Router,
  type RouteRecordRaw,
  type RouteRecordSingleView
} from 'vue-router'
import 'vue-router'
import { menuStructure, siteItems, siteStructureMainPointKey } from './siteStructure'
import { keyToCamel, keyToKebab, multiToSingleCharacter, strCap } from '@/lib/utils'
import type { MenuStructure, SiteItemKeys, SiteStructurePoint } from '@/types'
import { ref, type Ref } from 'vue'

export class SiteRouter {
  router: Router
  points: typeof siteItems
  routes: RouteRecordRaw[]
  currentRoute: Ref<SiteStructurePoint | null> = ref(null)
  activeMenuItemEntries: MenuStructure
  enabledSitePointEntries: [string, SiteStructurePoint][]
  enabledSiteFolderEntries: [string, SiteStructurePoint][]

  constructor(points: typeof siteItems) {
    this.points = points
    this.activeMenuItemEntries = this._activeMenuItemEntries()
    this.enabledSitePointEntries = this._enabledSitePointEntries
    this.enabledSiteFolderEntries = this._enabledSiteFolderEntries
    this.routes = this.routesGenerator
    this.router = createRouter({
      history: createWebHistory(import.meta.env.BASE_URL),
      routes: this.routes,
      scrollBehavior(to, from, savedPosition) {
        // Если нажали "назад", возвращаемся к предыдущей позиции скролла
        if (savedPosition) {
          return savedPosition
        }

        // Если есть якорь (хэш в URL), прокручиваем к якорю
        if (to.hash) {
          return {
            el: to.hash,
            behavior: 'smooth'
          }
        }

        // Прокручиваем наверх при переходе на новую страницу
        return { top: 0 }
      }
    })
    this.router.beforeEach(this.routerBeforeEach.bind(this))
  }

  /** обработчик маршрутов before
   *
   * @param to
   * @param from
   * @param next
   */
  private routerBeforeEach(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    next: NavigationGuardNext
  ) {
    const currentEntry = this.enabledSitePointEntries.find(([, point]) => point.name === to.name)
    document.title = currentEntry ? (currentEntry[1].title ?? 'P5S') : 'P5S'
    this.currentRoute.value = (this.enabledSitePointEntries.find(
      ([k, p]) => p.name === to.name
    ) ?? ['', null])[1]
    // TODO для других тегов

    next()
  }

  /** Генератор таблицы маршрутизации vue с проверками на совпадение name/path */
  private get routesGenerator(): RouteRecordRaw[] {
    const routes: RouteRecordRaw[] = []
    for (const [key, point] of this.enabledSitePointEntries) {
      const { path, breadcrumb } = this.pathBreadcrumbBySiteItemKey(key as SiteItemKeys) ?? {
        path: null,
        breadcrumb: []
      }
      const name = keyToCamel(key)
      if (!path)
        throw new Error(
          `Невозможно определить path для key: ${key}\n\n${JSON.stringify(point, undefined, 2)}`
        )
      point.path ??= path
      point.alias ??= key
      point.name ??= name
      point.title ??= strCap(key)
      point.breadcrumb ??= breadcrumb

      const route: RouteRecordRaw = {
        path,
        component: point.component,
        name
      } as RouteRecordSingleView

      // проверяем на дубли имён
      if (routes.find((i) => i.name === route.name))
        throw new Error(
          `Найдены повторяющиеся имена ${String(route.name)} для: key: ${key}\n\n${JSON.stringify(point, undefined, 2)}\n\n${JSON.stringify(route, undefined, 2)}`
        )

      // достраиваем path

      routes.push(route)
    }
    // console.log(siteItems, routes)
    return routes
  }

  /** получение всех актуальных поинтов сайта в ваде [key, point] */
  private get _enabledSitePointEntries(): [string, SiteStructurePoint][] {
    return Object.entries(this.points).filter(
      ([, p]) => typeof p === 'object' && p.enabled === undefined && !p.disabled
    ) as [string, SiteStructurePoint][]
  }

  /** получение всех актуальных папок сайта в ваде [key, folder] */
  private get _enabledSiteFolderEntries(): [string, SiteStructurePoint][] {
    return Object.entries(this.points).filter(([, p]) =>
      typeof p === 'object' ? p.enabled : p === true
    ) as [string, SiteStructurePoint][]
  }

  /** актуализатор меню, убирает задисабленные поинты и папки
   *
   * @param menuItem
   * @returns
   */
  private _activeMenuItemEntries(
    menuItem: MenuStructure = Object.values(menuStructure)[0] as MenuStructure
  ): MenuStructure {
    const result: Record<string, any> = {}
    for (const [key, value] of Object.entries(menuItem) as [
      SiteItemKeys,
      (MenuStructure & { disabled?: true }) | boolean
    ][]) {
      const item = siteItems[key]
      const itemEnabled =
        typeof value === 'boolean'
          ? value === false
            ? false
            : typeof item === 'object'
              ? item.enabled
                ? true
                : item.disabled
                  ? false
                  : true
              : item
          : value.disabled === true
            ? false
            : typeof item === 'object'
              ? item.enabled
                ? true
                : item.disabled
                  ? false
                  : true
              : item
      if (!itemEnabled) continue
      result[key] = this._activeMenuItemEntries(value as MenuStructure)
    }
    return result as MenuStructure
  }

  /** получение path по key
   *
   * @param keys
   * @returns
   */
  pathByKeys(keys: SiteItemKeys[]): string | null {
    const lastItem = siteItems[keys[keys.length - 1]]
    if (typeof lastItem === 'boolean') return null

    return multiToSingleCharacter(
      '/' +
        (lastItem.path ??
          keys
            .map((key) =>
              typeof siteItems[key] === 'object'
                ? siteItems[key].path === '/'
                  ? '/'
                  : (siteItems[key].pathLastPart ?? keyToKebab(siteItems[key].alias ?? key))
                : keyToKebab(key)
            )
            .join('/')),
      '/'
    ).toLowerCase()
  }

  /** получение полного пути по key
   *
   * @param key
   * @param menuNode
   * @param keys
   * @returns
   */
  pathBreadcrumbBySiteItemKey(
    key: SiteItemKeys,
    menuNode: MenuStructure = menuStructure,
    keys: SiteItemKeys[] = []
  ): {
    path: string | null
    breadcrumb: SiteItemKeys[]
  } | null {
    for (const currentKey in menuNode) {
      const currentMenuNode = menuNode[currentKey as keyof typeof menuNode]
      const currentKeys = [...keys, currentKey as SiteItemKeys]

      // Если ключ найден, возвращаем ноду и путь
      if (key === currentKey) {
        return {
          path: this.pathByKeys(currentKeys),
          breadcrumb: currentKeys.map((i) =>
            typeof siteItems[i] === 'object' ? (siteItems[i].alias ?? i) : i
          ) as SiteItemKeys[]
        }
      }

      // Если текущая нода — объект, продолжаем искать рекурсивно
      if (typeof currentMenuNode === 'object' && currentMenuNode !== null) {
        const result = this.pathBreadcrumbBySiteItemKey(key, currentMenuNode, currentKeys)

        if (result) {
          return result // Если найдено, возвращаем результат
        }
      }
    }
    if (keys.length === 0)
      // Если нода не найдена
      return { path: this.pathByKeys([key]), breadcrumb: [siteStructureMainPointKey, key] }
    else return null
  }

  /** Форматирование футера
   *
   * @param container
   */
  preatiFooter(container: HTMLElement) {
    const items = Array.from(container.children)
    const rows: Element[][] = []
    let currentRow: Element[] = []

    // Получаем Y-координату первого элемента
    let lastTop = items[0].getBoundingClientRect().top

    for (const item of items) {
      const itemTop = item.getBoundingClientRect().top

      if (itemTop === lastTop) {
        // Если элемент на той же строке, что и предыдущий
        currentRow.push(item)
      } else {
        // Если элемент на новой строке
        rows.push(currentRow)
        currentRow = [item]
        lastTop = itemTop // Обновляем Y-координату
      }
    }

    // Добавляем последнюю строку
    if (currentRow.length > 0) {
      rows.push(currentRow)
    }

    for (const row of rows) {
      for (const group of row as HTMLElement[]) {
        const head = group.querySelector('.footer-head') as HTMLElement
        if (head) head.style.height = ``
      }

      const rowMaxHeight = Math.max(
        ...row.map((i) => i.querySelector('.footer-head')?.getBoundingClientRect().height ?? 0)
      )
      for (const group of row) {
        const head = group.querySelector('.footer-head') as HTMLElement
        if (head) head.style.height = `${rowMaxHeight}px`
      }
    }

    if (rows[rows.length - 1].length === 1) {
      ;(rows[rows.length - 1][0] as HTMLElement).style.position = 'relative'
      ;(rows[rows.length - 1][0] as HTMLElement).style.left = 'calc(50% - 102.5px)'
    } else {
      ;(rows[rows.length - 1][rows[rows.length - 1].length - 1] as HTMLElement).style.position = ''
      ;(rows[rows.length - 1][rows[rows.length - 1].length - 1] as HTMLElement).style.left = ''
    }
  }
}

export const siteRouter = new SiteRouter(siteItems)

const router = siteRouter.router

export default router
