<script setup lang="ts">
import { type AboutUsItem } from '@/types'
import ButtonComponent from '../elements/ButtonComponent.vue'

const props = defineProps<AboutUsItem>()
</script>
<template>
  <div class="AboutUsItem">
    <div class="_desctop">
      <div class="_img" :style="{ backgroundImage: `url(/img/smi/78/${props.image})` }" />
      <div class="_content">
        <h3 v-html="props.topic" />
        <div class="body3" v-html="props.source" />
      </div>
      <div class="body3" v-html="props.date" />
      <div class="_link">
        <a :href="props.url">
          <ButtonComponent
            theme="two"
            size="small"
            text="Подробнее"
            :icon="{ iconId: 'svg-arrow', iconRotate: -135, embedded: { position: 'right' } }"
          />
        </a>
      </div>
    </div>
    <div class="_mobile">
      <div class="body3" v-html="props.date" />
      <h5 v-html="props.topic" />
      <a :href="props.url" v-html="props.source" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.AboutUsItem {
  ._desctop {
    display: flex;
    gap: 30px;
    min-height: 78px;
    ._img {
      flex-shrink: 0;
      width: 78px;
      background-repeat: no-repeat;
      background-size: 78px;
    }
    .body3 {
      color: var(--text-secondary);
    }
    ._content {
      flex-grow: 1;
    }
  }
  ._mobile {
    display: none;
    flex-direction: column;
    position: relative;
    gap: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--bg-medium);
    a {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media (max-width: 900px) {
    ._desctop {
      display: none;
    }
    ._mobile {
      display: flex;
    }
  }
}
</style>
