<script setup lang="ts">
import { secondaryTopMenu } from '@/router/siteStructure'
import SiteItemComponent from '../elements/SiteItemComponent.vue'
import type { SitePointKeys } from '@/types'
import { topMenu } from '@/lib/topMenu'
import IconComponent from '../icons/IconComponent.vue'
import { onMounted, onUnmounted } from 'vue'

const onClick = (event: Event) => {
  if (topMenu.openSecondaryMenu.value) {
    const openMenu = document.querySelector('.SecondaryTopMenu ._menu-more')
    if (openMenu !== event.target && !openMenu?.contains(event.target as Node))
      topMenu.openSecondaryMenu.value = false
  }
}

onMounted(() => {
  window.addEventListener('click', onClick)
})

onUnmounted(() => {
  window.removeEventListener('click', onClick)
})
</script>
<template>
  <div class="SecondaryTopMenu" id="SecondaryMenu">
    <div class="horizontal-limiter" ref="menuElement">
      <div class="_content">
        <div class="_first">
          <SiteItemComponent
            class="_link"
            v-on:click="topMenu.menuSecondaryClick(key, 1000)"
            :key="key"
            v-for="[key, alias] of Object.entries(secondaryTopMenu.first).filter(([, v]) => v) as [
              SitePointKeys,
              string | boolean
            ][]"
            :site-key="key"
            :alias="typeof alias === 'string' ? alias : undefined"
          />
          <div class="_menu-more" :class="{ _open: topMenu.openSecondaryMenu.value }">
            <div class="_more" v-on:click="topMenu.menuSecondaryClick()">
              <span>Ещё</span>
              <IconComponent
                style="width: 20px; height: 20px"
                icon-id="svg-chevron"
                :icon-rotate="topMenu.openSecondaryMenu.value ? 'top' : 'down'"
              />
            </div>
            <div class="_items"></div>
          </div>
        </div>
        <div class="_second">
          <SiteItemComponent
            class="_link"
            :key="key"
            v-for="[key, alias] of Object.entries(secondaryTopMenu.second).filter(([, v]) => v) as [
              SitePointKeys,
              string | boolean
            ][]"
            :site-key="key"
            :alias="typeof alias === 'string' ? alias : undefined"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.SecondaryTopMenu {
  margin-bottom: 15px;
  ._content {
    height: 20px;
    max-height: 20px;
    font-size: 14px;
    ._first,
    ._second {
      display: inline-block;
      top: 0;
      height: 100%;
      width: 47%;
      overflow: hidden;
      ._link {
        color: var(--text-primary);
        font-style: normal;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
      ._menu-more {
        cursor: pointer;
        font-weight: 500;
        display: inline-flex;
        flex-direction: column;
        margin-left: 10px;
        svg {
          margin: -5px 0;
        }
        ._items {
          display: none;
          position: absolute;
          flex-direction: column;
          margin: 20px 0 0 -50px;
          padding: 20px;
          background-color: var(--bg-light);
        }
        &._open ._items {
          display: flex;
          gap: 15px;
        }
      }
    }
    ._first {
      margin-right: 6%;
    }
    ._second {
      text-align: end;
    }
    @media (max-width: 600px) {
      ._first {
        display: none;
      }
      ._second {
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
