<script setup lang="ts">
import type { NewsItem } from '@/types'

const props = defineProps<NewsItem>()
</script>
<template>
  <div class="NewsItem">
    <div class="_img" :style="{ backgroundImage: `url(/img/news/80/${props.image})` }" />
    <div class="_content">
      <h4 v-html="props.topic" />
      <div class="caption" v-html="props.date" />
      <div class="body3" v-html="props.anonce" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.NewsItem {
  width: 50%;
  display: flex;
  gap: 15px;
  ._img {
    flex-shrink: 0;
    width: 80px;
    background-repeat: no-repeat;
    background-size: 80px;
    margin-top: 7px;
  }
  ._content {
    flex-grow: 1;
  }
  h4 {
    margin-bottom: 30px;
  }
  .caption {
    margin-bottom: 10px;
  }
  @media (max-width: 1000px) {
    width: 75%;
  }
  @media (max-width: 800px) {
    width: 90%;
    ._img {
      display: none;
    }
  }
}
</style>
