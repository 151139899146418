<script setup lang="ts">
import HorizontalSliderComponent from '@/components/widgets/SliderComponent.vue'
const items: string[] = [
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp',
  '1.webp'
]
</script>
<template>
  <div class="Brands">
    <div class="horizontal-limiter">
      <HorizontalSliderComponent name="Популярные бренды">
        <div
          class="_brand"
          :key="k"
          v-for="[k, item] of items.entries()"
          :style="{ backgroundImage: `url(/img/brands/160/${item})` }"
        />
      </HorizontalSliderComponent>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.Brands {
  padding-top: 60px;
  padding-bottom: 60px;
  ._brand {
    width: 114px;
    aspect-ratio: 1;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
