<script setup lang="ts">
import type { IconProps } from '@/types'
import IconComponent from '../icons/IconComponent.vue'

interface Props {
  theme?: 'one' | 'two' | 'three' | 'four' | 'five' | 'icon'
  size?: 'large' | 'medium' | 'small'
  icon?: IconProps
  disabled?: boolean
  text: string
  type?: 'reset' | 'submit' | 'button'
  href?: string
}
const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  theme: 'one',
  size: 'large'
})

const emit = defineEmits(['on-click'])

const handleClick = () => {
  emit('on-click')
}
</script>
<template>
  <button
    @click="handleClick"
    :type="props.type"
    :disabled="disabled"
    :class="`_${props.theme} _${props.size}`"
  >
    <IconComponent v-if="props.icon && props.theme === 'icon'" v-bind="props.icon" />
    <template v-else>
      <IconComponent
        v-if="props.icon && props.icon.embedded?.position !== 'right'"
        v-bind="props.icon"
      />
      <span v-html="props.text" />
      <IconComponent
        v-if="props.icon && props.icon.embedded?.position === 'right'"
        v-bind="props.icon"
      />
    </template>
  </button>
</template>
<style lang="scss" scoped>
button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  border-radius: 2px;
  color: var(--text-contrast-inverse);
  cursor: pointer;
  height: 40px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 500;
  &._icon {
    padding: 0;
    background: transparent;
  }
  &._one {
    background: var(--base-color);
    &:hover {
      background: var(--button-accent-bg);
    }
  }
  &._two {
    background: var(--button-second-bg);
    &:hover {
      background: var(--base-color);
    }
  }
  &._four {
    background-color: transparent;
    border: solid 1px var(--button-four-color);
    color: var(--button-four-color);
    &:hover {
      color: var(--button-four-hovered);
      border-color: var(--button-four-hovered);
    }
  }
  &._five {
    height: 50px;
    border-radius: 4px;
    padding: 0 30px;
    background: var(--button-accent-bg);
    &:hover {
      background: var(--base-color);
    }
  }
  &:disabled {
    cursor: inherit;
    color: var(--text-disabled);
    background: var(--bg-light);
    &:hover {
      color: var(--text-disabled);
      background: var(--bg-light);
    }
  }
  &._small {
    gap: 5px;
    height: 25px;
    padding: 0 10px;
    font-weight: 400;
  }
}
</style>
