<script setup lang="ts">
import { siteRouter } from '@/router'
import SiteItem from './elements/SiteItemComponent.vue'
import { onMounted, onUnmounted, ref } from 'vue'
import type { MenuStructure, SiteItemKeys } from '@/types'
import { LINK_DZEN, LINK_TG, LINK_VK } from '@/lib/const'
import IconComponent from './icons/IconComponent.vue'

const mapElement = ref<HTMLElement | null>(null)
const onResize = () => {
  if (mapElement.value) siteRouter.preatiFooter(mapElement.value)
}

onMounted(() => {
  if (mapElement.value) siteRouter.preatiFooter(mapElement.value)
  window.addEventListener('resize', onResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})
</script>

<template>
  <footer>
    <nav class="horizontal-limiter">
      <ul class="div _map" ref="mapElement">
        <li
          class="_group"
          :key="groupKey"
          v-for="[groupKey, value] in Object.entries(siteRouter.activeMenuItemEntries) as [
            SiteItemKeys,
            MenuStructure
          ][]"
        >
          <SiteItem class="footer-head" :site-key="groupKey" />
          <ul class="div _sub">
            <li :key="itemKey" v-for="itemKey in Object.keys(value) as SiteItemKeys[]">
              <SiteItem class="footer-subhead" :site-key="itemKey" />
            </li>
          </ul>
        </li>
        <li class="_group _doc">
          <ul class="div _sub">
            <li class="footer-doc">
              <SiteItem class="footer-subhead" site-key="политика конфиденциальности" />
            </li>
            <li class="footer-doc">
              <SiteItem
                class="footer-subhead"
                site-key="согласие на обработку персональных данных"
              />
            </li>
            <li class="_social">
              <a :href="LINK_TG">
                <IconComponent icon-id="svg-telegram-circle" icon-color="white" />
              </a>
              <a :href="LINK_VK">
                <IconComponent icon-id="svg-vk-circle" icon-color="white" />
              </a>
              <a :href="LINK_DZEN">
                <IconComponent icon-id="svg-dzen-circle" icon-color="white" />
              </a>
            </li>
            <li class="footer-copyright">
              <span class="footer-subhead"
                >© ООО «Поставщик счастья», 2011 — {{ new Date().getFullYear() }}</span
              >
            </li>
          </ul>
        </li>
      </ul>
      <div class="_mobile">
        <ul class="div _sub">
          <li class="footer-doc">
            <SiteItem class="footer-subhead" site-key="политика конфиденциальности" />
          </li>
          <li class="footer-doc">
            <SiteItem class="footer-subhead" site-key="согласие на обработку персональных данных" />
          </li>
          <li class="_social">
            <a :href="LINK_TG">
              <IconComponent icon-id="svg-telegram-circle" icon-color="black" :icon-width="25" />
            </a>
            <a :href="LINK_VK">
              <IconComponent icon-id="svg-vk-circle" icon-color="black" :icon-width="25" />
            </a>
            <a :href="LINK_DZEN">
              <IconComponent icon-id="svg-dzen-circle" icon-color="black" :icon-width="25" />
            </a>
          </li>
          <li class="footer-copyright">
            <span class="footer-subhead"
              >© ООО «Поставщик счастья», 2011 — {{ new Date().getFullYear() }}</span
            >
          </li>
        </ul>
      </div>
    </nav>
  </footer>
</template>

<style scoped lang="scss">
footer {
  background: var(--footer-bg);
  font-size: 10px;
  ._map {
    margin: 60px 0;
    flex-wrap: wrap;
    display: inline-flex;
    justify-content: space-between;
    gap: clamp(10px, 2%, 30px);
    ._group {
      &._doc {
        padding-top: 30px;
        ._social {
          padding: 40px 0 20px 0;
          svg {
            margin-right: 20px;
          }
        }
      }
      width: 205px;
      ._sub {
        margin-top: 15px;
      }
    }
  }
  ._mobile {
    display: none;
  }
  @media (max-width: 480px) {
    ._map {
      display: none;
    }
    ._mobile {
      display: block;
      * {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
      ._sub {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 30px 0;
        svg {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
