<script setup lang="ts">
import type { InputFieldBind } from '@/types/forms'

const props = withDefaults(
  defineProps<InputFieldBind & { modelValue: string | number | undefined }>(),
  {
    type: 'text',
    required: false
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
}>()
const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.value)
}

const required = props.required === 'false' ? false : !!props.required
</script>
<template>
  <div class="Input">
    <label><span v-html="props.label" /><span class="_req" v-if="required">*</span></label>
    <textarea
      v-if="props.type === 'textarea'"
      :value="props.modelValue"
      @input="onInput"
      :placeholder="placeholder"
      :required="required"
    />
    <input
      v-else
      :type="props.type"
      :value="props.modelValue"
      @input="onInput"
      :placeholder="placeholder"
      :required="required"
    />
  </div>
</template>
<style lang="scss" scoped>
.Input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    display: block;
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      &._req {
        color: var(--error);
      }
    }
  }
  input,
  textarea {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    height: 40px;
    padding: 0 10px;
    border: none;
    &::placeholder {
      color: var(--text-disabled);
    }
  }
  textarea {
    min-height: 102px;
    padding: 10px;
    resize: none;
    overflow: auto;
  }
}
</style>
