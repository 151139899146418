<script setup lang="ts">
import { ref } from 'vue'
import IconComponent from '../icons/IconComponent.vue'
import ButtonComponent from '../elements/ButtonComponent.vue'
import SiteItemComponent from '../elements/SiteItemComponent.vue'
import LogoComponent from '../widgets/LogoComponent.vue'
import { siteItems } from '@/router/siteStructure'
import { useRouter } from 'vue-router'
import { topMenu } from '@/lib/topMenu'

const router = useRouter()
const searchQuery = ref('')
function handleSearchSubmit() {
  const searchPage = siteItems['поиск по сайту']
  router.push({ name: searchPage.name, query: { search: searchQuery.value } })
}
</script>
<template>
  <div class="LogoLine">
    <div class="horizontal-limiter">
      <div class="_logo-holder">
        <LogoComponent class="_logo-limiter" />
      </div>
      <SiteItemComponent site-key="каталог">
        <ButtonComponent
          class="_catalog"
          text="Каталог"
          :disabled="false"
          :icon="{ iconId: 'svg-catalog', embedded: { position: 'left' } }"
        />
      </SiteItemComponent>
      <form class="_search" @submit.prevent="handleSearchSubmit">
        <input type="search" placeholder="Поиск по сайту" v-model="searchQuery" />
        <ButtonComponent
          class="_search-button"
          text="Поиск"
          theme="icon"
          :icon="{ iconId: 'svg-search' }"
          type="submit"
        />
      </form>
      <SiteItemComponent class="_signin" site-key="вход на сайт" :icon="{ iconId: 'svg-user' }" />
      <IconComponent
        class="_sandwich"
        icon-id="svg-sandwich"
        v-on:click="topMenu.mobileMenuView.value = true"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.LogoLine {
  height: 40px;
  margin-bottom: 15px;
  .horizontal-limiter {
    max-height: 40px;
    margin: 15px auto;
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    * {
      flex-shrink: 0;
    }
  }
  ._logo-limiter {
    width: 160px;
  }
  ._search {
    flex-grow: 1;
    height: 100%;
    position: relative;
    display: flex;
    border-radius: 2px;
    border: 1px solid #959bad;
    input {
      flex-grow: 1;
      border: none;
      z-index: 1;
      height: 100%;
      padding: 10px 5px 10px 15px;
      font-size: 16px;
      outline: none;
      width: 140px;
    }
    ._search-button {
      flex-shrink: 0;
      padding: 0 15px 0 5px;
      border-radius: 2px;
      display: flex;
      color: var(--icon-secondary);
    }
  }
  ._signin {
    color: var(--base-color);
  }
  ._sandwich {
    display: none;
  }
  @media (max-width: 800px) {
    ._catalog {
      display: none;
    }
  }

  @media (max-width: 480px) {
    ._logo-holder {
      flex-grow: 1;
    }
    ._logo-limiter {
      width: 140px;
    }
    & {
      height: 65px;
      z-index: 99;
      position: fixed;
      top: 0;
      width: 100%;
      background-color: var(--bg-auto);
      .horizontal-limiter {
        max-height: 35px;
      }
    }
    ._signin {
      display: none;
    }
    ._search {
      flex-grow: 0;
      justify-content: end;
      border-color: transparent;
      input {
        display: none;
      }
      ._search-button {
        background-color: var(--bg-light);
        color: var(--base-color);
        width: 35px;
        height: 35px;
        padding: 7.5px;
      }
    }
    ._sandwich {
      display: block;
      cursor: pointer;
    }
  }

  @media (max-width: 360px) {
    ._search {
      display: none;
    }
  }
}
</style>
