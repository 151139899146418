<script setup lang="ts">
import LogoLineComponent from './LogoLineComponent.vue'
import TopMenuComponent from './TopMenuComponent.vue'
import SecondaryMenuComponent from './SecondaryMenuComponent.vue'
import { topMenu } from '@/lib/topMenu'
import { onMounted, onUnmounted } from 'vue'
import MobileMenuComponent from './MobileMenuComponent.vue'

const onResize = () => {
  topMenu.horizontalViewIndexProcessor()
}

onMounted(() => {
  window.addEventListener('resize', onResize)
  topMenu.init()
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})
</script>

<template>
  <header>
    <MobileMenuComponent />
    <TopMenuComponent />
    <LogoLineComponent />
    <SecondaryMenuComponent />
  </header>
</template>

<style scoped lang="scss">
header {
  padding-top: 30px;
}
@media (max-width: 480px) {
  header {
    padding-top: 65px;
  }
}
</style>
