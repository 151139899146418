import { siteRouter } from '@/router'
import { ref, type Ref } from 'vue'
import type { SiteFolderKeys, SiteItemKeys } from '../types'
import { sleep } from './utils'

export class TopMenu {
  horizontalMainViewLenght: Ref<number>
  rightMainXs: number[] = []
  moreMainWidth: number = 0
  initialPadding: number = 0
  openMainMenu: Ref<SiteFolderKeys | null | '+'> = ref(null)
  openSecondaryMenu = ref(false)
  openMainMenuMore: Ref<SiteFolderKeys | null> = ref(null)
  mobileMenuView = ref(false)

  secondMenuWidths: number[] = []

  constructor() {
    this.horizontalMainViewLenght = ref(Object.keys(siteRouter.activeMenuItemEntries).length)
  }

  /** инициализация верхнего меню
   *
   * @param topMenu
   */
  async init() {
    this.secondMenuWidths = Array.from(
      document.querySelectorAll('#SecondaryMenu ._first ._link')
    ).map((i) => i.getBoundingClientRect().width)

    const topMenu: HTMLElement = document.getElementById('TopMenu')!
    this.initialPadding = topMenu.querySelector('._content')!.getBoundingClientRect().left
    this.moreMainWidth =
      topMenu.querySelector('#_menu-more span')!.getBoundingClientRect().width + 40
    this.rightMainXs = Array.from(topMenu.querySelectorAll('._group').entries()).map(
      ([, element]) => element.getBoundingClientRect().right
    )
    let tryCount = 0
    while (
      tryCount < 4 &&
      topMenu.querySelector('#_menu-more')!.getBoundingClientRect().right >
        topMenu.querySelector('._right-block')!.getBoundingClientRect().left
    ) {
      await sleep(50)
      this.horizontalViewIndexProcessor()
      tryCount++
    }

    topMenu.querySelector('._menu')?.classList.remove('opacity')
  }

  async horizontalViewIndexProcessor() {
    if (window.innerWidth <= 480 && this.openMainMenu.value) this.openMainMenu.value = null
    // topMenu
    const topMenu: HTMLElement = document.getElementById('TopMenu')!
    const rightBlockLeft = topMenu.querySelector('._right-block')!.getBoundingClientRect().left
    const currentPadding = topMenu.querySelector('._content')!.getBoundingClientRect().left
    const markerX = rightBlockLeft - this.moreMainWidth - 40
    this.horizontalMainViewLenght.value = this.rightMainXs.filter(
      (i) => i - (this.initialPadding - currentPadding) <= markerX
    ).length

    //secondMenu
    const secMenu: HTMLElement = document.getElementById('SecondaryMenu')!
    function secMenuLines(): number {
      return [
        ...new Set(
          Array.from(secMenu.querySelectorAll('._first > *')).map(
            (i) => i.getBoundingClientRect().top
          )
        )
      ].length
    }
    function getSecondItems(): Element[] {
      return Array.from(secMenu.querySelectorAll('._first > *'))
    }

    if (secMenuLines() > 1) {
      while (secMenuLines() > 1 && getSecondItems().length > 1) {
        secMenu
          .querySelector('._menu-more ._items')!
          .prepend(getSecondItems()[getSecondItems().length - 2])
      }
    } else if (
      secMenuLines() === 1 &&
      secMenu.querySelector('._menu-more ._items')!.childElementCount > 0
    ) {
      while (
        secMenu.querySelector('._menu-more ._items')!.childElementCount > 0 &&
        secMenu.querySelector('._first ._more')!.getBoundingClientRect().right +
          this.secondMenuWidths[getSecondItems().length] <
          secMenu.querySelector('._first')!.getBoundingClientRect().right
      ) {
        secMenu
          .querySelector('._first')!
          .insertBefore(
            secMenu.querySelector('._menu-more ._items')!.firstChild as Element,
            secMenu.querySelector('._menu-more')!
          )
      }
    }
  }

  private _waitTimer: any
  menuClick(key: SiteFolderKeys | '+', wait?: number) {
    clearTimeout(this._waitTimer)
    if (wait) {
      this._waitTimer = setTimeout(
        () => (this.openMainMenu.value = key === this.openMainMenu.value ? null : key),
        wait
      )
    } else {
      this.openMainMenu.value = key === this.openMainMenu.value ? null : key
    }
  }

  menuMoreClick(key: SiteFolderKeys) {
    this.openMainMenuMore.value = key === this.openMainMenuMore.value ? null : key
  }

  private _waitTimerSec: any
  menuSecondaryClick(key?: SiteItemKeys, wait?: number) {
    clearTimeout(this._waitTimerSec)
    if (key) {
      if (wait) {
        this._waitTimerSec = setTimeout(() => (this.openSecondaryMenu.value = false), wait)
      } else {
        this.openSecondaryMenu.value = false
      }
    } else {
      this.openSecondaryMenu.value = !this.openSecondaryMenu.value
    }
  }
}

export const topMenu = new TopMenu()
