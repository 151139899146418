<script setup lang="ts"></script>
<template>
  <div class="Logo">
    <svg class="_logo"><use href="#svg-logo" /></svg>
    <svg class="_slogan"><use href="#svg-slogan" /></svg>
    <svg class="_b2b2c"><use href="#svg-b2b2c" /></svg>
  </div>
</template>
<style lang="scss" scoped>
.Logo {
  width: 100%;
  aspect-ratio: 160/40;
  position: relative;
}
._logo {
  height: 100%;
  aspect-ratio: 25/40;
  position: absolute;
  top: 0;
  left: 0;
}
._slogan {
  color: #3e4454;
  width: calc(100% / 160 * 122.4);
  aspect-ratio: 1224/148;
  position: absolute;
  top: calc(100% / 40 * 4.5);
  right: calc(100% / 160 * 4);
}

._b2b2c {
  color: #3e4454;
  width: calc(100% / 160 * 41.4);
  aspect-ratio: 414/96;
  position: absolute;
  bottom: calc(100% / 40 * 4);
  right: calc(100% / 160 * 4);
}
.svg-b2b2c_line {
  fill: #fad6d7;
}

@media (max-width: 480px) {
  ._b2b2c {
    right: calc(100% / 160 * 89.7);
  }
}

@media (prefers-color-scheme: dark) {
  ._slogan,
  ._b2b2c {
    color: #bec4d4;
  }
  ._b2b2c_line {
    fill: #6a1617;
  }
}
</style>
