<script setup lang="ts">
import { iconProcessor } from '@/lib/iconProcessor'
import type { IconProps } from '@/types'
import { watch, ref } from 'vue'

const props = defineProps<IconProps>()
const svg = ref(iconProcessor.svg(props))

watch(
  () => props,
  (newProps) => {
    svg.value = iconProcessor.svg(newProps)
  },
  { deep: true }
)
</script>

<template>
  <svg :style="svg.style" xmlns="http://www.w3.org/2000/svg">
    <use :href="svg.id" />
  </svg>
</template>
