<script setup lang="ts">
import type { SelectFieldBind } from '@/types/forms'

const props = withDefaults(defineProps<SelectFieldBind & { modelValue: string | undefined }>(), {
  required: false
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
}>()

const required = props.required === 'false' ? false : !!props.required

const onChange = (event: Event) => {
  const target = event.target as HTMLSelectElement
  emit('update:modelValue', target.value) // Отправляем новое значение при изменении
}
</script>
<template>
  <div class="Select">
    <label><span v-html="props.label" /><span class="_req" v-if="required">*</span></label>
    <select
      :value="props.modelValue"
      @change="onChange"
      :required="required"
      :class="{ _notSelected: !props.modelValue }"
    >
      <option
        v-if="props.placeholder"
        selected
        disabled
        hidden
        value=""
        v-html="props.placeholder"
      />
      <option
        :key="k"
        v-for="[k, text] of Object.entries(props.options)"
        v-html="text"
        :value="k"
      />
    </select>
  </div>
</template>
<style lang="scss" scoped>
.Select {
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    display: block;
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      &._req {
        color: var(--error);
      }
    }
  }
  select {
    padding: 10px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    height: 40px;
    border: none;
    &._notSelected {
      color: var(--text-secondary);
    }
  }
}
</style>
