<script setup lang="ts">
import HorizontalSliderComponent from '@/components/widgets/SliderComponent.vue'
import NewsItemComponent from '@/components/widgets/NewsItemComponent.vue'
import type { NewsItem } from '@/types'
const items: NewsItem[] = [
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья',
    date: '6 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  },
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья',
    date: '7 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  },
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья',
    date: '8 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  },
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья',
    date: '9 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  },
  {
    image: '1.webp',
    topic: 'Расширяем ассортимент нижнего белья',
    date: '10 июня 2021',
    anonce:
      'Нижнее белье как часть большого сегмента fashion в фокусе внимания всех ключевых российских маркетплейсов. Поддерживая этот тренд, «Поставщик счастья» также развивает ассортимент категории.'
  }
]
</script>
<template>
  <div class="News">
    <div class="horizontal-limiter">
      <HorizontalSliderComponent name="Сводка новостей">
        <NewsItemComponent :key="k" v-for="[k, item] of items.entries()" v-bind="item" />
      </HorizontalSliderComponent>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.News {
  background-color: var(--bg-medium);
  padding-top: 60px;
  padding-bottom: 60px;
  ::v-deep * {
    color: var(--text-contrast-inverse);
  }
}
</style>
